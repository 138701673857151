import { componentResolver } from './prismic-component-resolver';
import { linkResolver } from './prismic-link-resolver';

export const repositoryConfigs = [
  {
    repositoryName: 'entities-ff',
    linkResolver,
    componentResolver,
  },
];

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React, { Component } from 'react';
import styled from 'styled-components';

import FlagAu from '../assets/images/flags/au.svg';
import FlagBr from '../assets/images/flags/br.svg';
import FlagCa from '../assets/images/flags/ca.svg';
import FlagCo from '../assets/images/flags/co.svg';
import FlagEs from '../assets/images/flags/es.svg';
import FlagFr from '../assets/images/flags/fr.svg';
import FlagGb from '../assets/images/flags/gb.svg';
import FlagHk from '../assets/images/flags/hk.svg';
import FlagId from '../assets/images/flags/id.svg';
import FlagIn from '../assets/images/flags/in.svg';
import FlagIt from '../assets/images/flags/it.svg';
import FlagJp from '../assets/images/flags/jp.svg';
import FlagMx from '../assets/images/flags/mx.svg';
import FlagMy from '../assets/images/flags/my.svg';
import FlagPe from '../assets/images/flags/pe.svg';
import FlagPt from '../assets/images/flags/pt.svg';
import FlagSg from '../assets/images/flags/sg.svg';
import FlagTw from '../assets/images/flags/tw.svg';
import FlagUs from '../assets/images/flags/us.svg';

const Div = styled.div`
  .flag-icon {
    display: inline-block;
    margin-left: 5px;
    height: 12px;
    position: relative;
    top: -1px;
    width: auto;
  }
`;

const IconDropdown = (
  <svg
    className="absolute"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.29826 8.34745L15.1077 8.36018L15.1204 15.1696L13.8222 15.1696L13.8731 10.5621L9.02375 15.4114L8.05643 14.4441L12.9058 9.59478L8.29826 9.6457L8.29826 8.34745Z"
      fill="#3D3D3D"
    />
  </svg>
);

class CountrySelector extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Div className="nav--dropdown--trigger relative">
        <a className="btn--text btn--text--language color--black ml-20 md:ml-0">
          {this.props.langKey === 'en-us' && (
            <>
              United States <img className="flag-icon" src={FlagUs} alt="" />
            </>
          )}
          {this.props.langKey === 'en-au' && (
            <>
              Australia <img className="flag-icon" src={FlagAu} alt="" />
            </>
          )}
          {this.props.langKey === 'en-ca' && (
            <>
              Canada <img className="flag-icon" src={FlagCa} alt="" />
            </>
          )}
          {this.props.langKey === 'pt-br' && (
            <>
              Brasil <img className="flag-icon" src={FlagBr} alt="" />
            </>
          )}
          {this.props.langKey === 'es-co' && (
            <>
              Colombia <img className="flag-icon" src={FlagCo} alt="" />
            </>
          )}
          {this.props.langKey === 'es-es' && (
            <>
              España <img className="flag-icon" src={FlagEs} alt="" />
            </>
          )}
          {this.props.langKey === 'fr-fr' && (
            <>
              France <img className="flag-icon" src={FlagFr} alt="" />
            </>
          )}
          {this.props.langKey === 'zh-hk' && (
            <>
              香港 <img className="flag-icon" src={FlagHk} alt="" />
            </>
          )}
          {this.props.langKey === 'en-in' && (
            <>
              India <img className="flag-icon" src={FlagIn} alt="" />
            </>
          )}
          {this.props.langKey === 'id-id' && (
            <>
              Indonesia <img className="flag-icon" src={FlagId} alt="" />
            </>
          )}
          {this.props.langKey === 'it-it' && (
            <>
              Italia <img className="flag-icon" src={FlagIt} alt="" />
            </>
          )}
          {this.props.langKey === 'ja-jp' && (
            <>
              日本国 <img className="flag-icon" src={FlagJp} alt="" />
            </>
          )}
          {this.props.langKey === 'en-my' && (
            <>
              English - Malaysia{' '}
              <img className="flag-icon" src={FlagMy} alt="" />
            </>
          )}
          {this.props.langKey === 'ms-my' && (
            <>
              Malay - Malaysia <img className="flag-icon" src={FlagMy} alt="" />
            </>
          )}
          {this.props.langKey === 'es-mx' && (
            <>
              México <img className="flag-icon" src={FlagMx} alt="" />
            </>
          )}
          {this.props.langKey === 'es-pe' && (
            <>
              Perú <img className="flag-icon" src={FlagPe} alt="" />
            </>
          )}
          {this.props.langKey === 'pt-pt' && (
            <>
              Portugal <img className="flag-icon" src={FlagPt} alt="" />
            </>
          )}
          {this.props.langKey === 'en-sg' && (
            <>
              Singapore <img className="flag-icon" src={FlagSg} alt="" />
            </>
          )}
          {this.props.langKey === 'zh-tw' && (
            <>
              台灣 <img className="flag-icon" src={FlagTw} alt="" />
            </>
          )}
          {this.props.langKey === 'en-gb' && (
            <>
              United Kingdom <img className="flag-icon" src={FlagGb} alt="" />
            </>
          )}
          <svg
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.82096 0.0952621L0.48763 3.29382L1.12196 3.90479L3.82096 1.49183L6.51997 3.90479L7.1543 3.29382L3.82096 0.0952621Z"
              fill="white"
            />
          </svg>
        </a>
        <div className="nav--dropdown nav--dropdown--language bg--white absolute hidden p-20 pb-10 pt-10">
          {this.props.langKey !== 'en-au' && (
            <AniLink
              to="/en-au/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Australia {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'pt-br' && (
            <AniLink
              to="/pt-br/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Brasil {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'en-ca' && (
            <AniLink
              to="/en-ca/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Canada {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'es-co' && (
            <AniLink
              to="/es-co/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Colombia {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'es-es' && (
            <AniLink
              to="/es-es/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              España {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'fr-fr' && (
            <AniLink
              to="/fr-fr/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              France {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'zh-hk' && (
            <AniLink
              to="/zh-hk/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              香港 {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'en-in' && (
            <AniLink
              to="/en-in/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              India {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'id-id' && (
            <AniLink
              to="/id-id/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Indonesia {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'it-it' && (
            <AniLink
              to="/it-it/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Italia {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'ja-jp' && (
            <AniLink
              to="/ja-jp/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              日本国 {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'en-my' && (
            <AniLink
              to="/en-my/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Malaysia - English {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'ms-my' && (
            <AniLink
              to="/ms-my/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Malaysia - Malay {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'es-mx' && (
            <AniLink
              to="/es-mx/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              México {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'es-pe' && (
            <AniLink
              to="/es-pe/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Perú {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'pt-pt' && (
            <AniLink
              to="/pt-pt/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Portugal {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'en-sg' && (
            <AniLink
              to="/en-sg/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              Singapore {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'zh-tw' && (
            <AniLink
              to="/zh-tw/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              台灣 {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'en-gb' && (
            <AniLink
              to="/en-gb/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              United Kingdom {IconDropdown}
            </AniLink>
          )}
          {this.props.langKey !== 'en-us' && (
            <AniLink
              to="/"
              cover
              direction="up"
              duration={1}
              bg="#FAFAFA"
              className="color--grey-6 relative block pb-10 pt-10">
              United States {IconDropdown}
            </AniLink>
          )}
        </div>
      </Div>
    );
  }
}
export default CountrySelector;

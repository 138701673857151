import { createGlobalStyle } from 'styled-components';

import { media } from './media';

const RtlStyles = createGlobalStyle`
  .wrapper--rtl {
    direction: rtl;
    .cta {
      @media ${media.mdUp} {
        text-align: right;
      }
      svg {
        margin-left: 0;
        margin-right: 16px;
      }
    }
    .logo--wrapper {
      left: auto;
      right: 33px;
    }
    .nav--icon--menu {
      padding-left: 0;
      padding-right: 34px;
    }
    .nav--dropdown a {
      padding-right: 24px;
    }
    .nav--dropdown .flag-icon {
      margin-left: 0;
      margin-right: 5px;
    }
    .nav--dropdown--trigger > a > svg {
      margin-left: 0;
      margin-right: 4px;
    }
    .nav--dropdown--trigger:hover .nav--dropdown--language {
      left: 0;
      right: auto;
    }
    .nav--links li.mr-45 {
      margin-right: 0;
      margin-left: 45px;
    }
    .search--holder {
      right: auto;
      left: 0;
      .search--input {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .category--dropdown {
      left: auto;
      right: -20px;
    }
    .category--dropdown--trigger .btn--text span {
      padding-right: 0 !important;
      padding-left: 16px;
    }
    .category--dropdown--trigger .btn--text svg.inline-block {
      right: auto !important;
      left: 0 !important;
    }
    #contact {
      direction: ltr;
      .h3,
      .richtext--holder,
      .form--element--placeholder,
      .form--element--select,
      .xsmall,
      .form--element--text,
      .form--element--label,
      .phone--holder {
        direction: rtl;
      }
      .form--element--text,
      .form--element--border {
        left: auto;
        right: 0;
      }
      .cta {
        direction: rtl;
        @media ${media.mdUp} {
          padding-right: calc(37.5% + 50px);
        }
        @media ${media.lgUp} {
          padding-right: 50%;
        }
        @media ${media.xlUp} {
          padding-right: 58.333%;
        }
      }
      .h3.col-span-4 {
        @media ${media.lgUp} {
          grid-column-start: 3;
        }
        @media ${media.xlUp} {
          grid-column-start: 2;
        }
      }
      .richtext--holder {
        @media ${media.mdUp} {
          padding-right: 0;
          grid-column: span 4 / span 4;
        }
        @media ${media.lgUp} {
          grid-column: span 6 / span 6;
          padding-right: 0;
        }
        @media ${media.xlUp} {
          grid-column: span 5 / span 5;
        }
      }
      .form-1 .col-span-4 {
        @media ${media.mdUp} {
          padding-right: 50px;
        }
        @media ${media.lgUp} {
          padding-right: 0;
        }
        @media ${media.xlUp} {
          grid-column: span 5 / span 5;
        }
      }
    }
    #block-marquee {
      direction: ltr;
    }
    #block-testimonial {
      .caption.pr-50 {
        padding-right: 0;
        padding-left: 50px;
      }
      @media ${media.lgUp} {
        .stat {
          padding-left: 0;
          padding-right: 60px;
          .icon {
            left: auto;
            right: 0;
          }
        }
      }
    }
    #block-hero-full-bleed-2 {
      .image--holder {
        @media ${media.mdUp} {
          margin-right: 0;
          margin-left: -60px;
        }
      }
      .richtext--holder {
        @media ${media.mdUp} {
          padding-right: 0;
          padding-left: 30px;
        }
      }
    }
    #block-blogs,
    #block-hero-blog {
      .blog--card--image,
      .blog--card--text {
        padding-right: 0;
        padding-left: 30px;
      }
    }
  }
`;

export default RtlStyles;

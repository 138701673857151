import { createGlobalStyle } from 'styled-components';

import { media } from './media';

const GlobalStyles = createGlobalStyle`
  :root {
    --white: #fffef5;
    --white-rgb: 255,254,245;
    --grey-1: #FAFAFA;
    --grey-2: #F2F2F2;
    --grey-3: #D3D3D3;
    --grey-4: #B1B1B1;
    --grey-4-rgb: 177,177,177;
    --grey-5: #58574E;
    --grey-6: #3D3D3D;
    --black: #262421;
    --main: ${(props) =>
      props.data.edges[0].node.data.color_primary
        ? props.data.edges[0].node.data.color_primary
        : '#C74741'};
    --accent-1: ${(props) =>
      props.data.edges[0].node.data.color_accent_1
        ? props.data.edges[0].node.data.color_accent_1
        : '#DBFF00'};
    --accent-2: ${(props) =>
      props.data.edges[0].node.data.color_accent_2
        ? props.data.edges[0].node.data.color_accent_2
        : '#E26142'};
    --accent-3: ${(props) =>
      props.data.edges[0].node.data.color_accent_3
        ? props.data.edges[0].node.data.color_accent_3
        : '#AFEEF8'};

    --blue: #87A1A5;

    --grey-3-rgb: 230,229,222;
    --green-dark: #043532;
    --green-dark-rgb: 4,53,50;
    --green: #045651;
    --green-rgb: 4,86,81;
    --green-light: #BBDDCD;
    --green-light-rgb: 187,221,205;
    --orange-dark: #9D2407;
    --orange-dark-rgb: 157,36,7;
    --orange: #D24821;
    --orange-rgb: 210,72,33;
    --orange-light: #F2BAB3;
    --orange-light-rgb: 242,186,179;
    --yellow-dark: #B7661B;
    --yellow-dark-rgb: 183,102,27;
    --yellow: #E7983B;
    --yellow-rgb: 231,152,59;
    --yellow-light: #FFD6A9;
    --yellow-light-rgb: 255,214,169;
    --primary-dark: var(--green-dark);
    --primary-dark-rgb: var(--green-dark-rgb);
    --primary: var(--green);
    --primary-rgb: var(--green-rgb);
    --primary-light: var(--green-light);
    --primary-light-rgb: var(--green-light-rgb);
    --secondary-dark: var(--orange-dark);
    --secondary-dark-rgb: var(--orange-dark-rgb);
    --secondary: var(--blue);
    --secondary-rgb: var(--orange-rgb);
    --secondary-light: var(--orange-light);
    --secondary-light-rgb: var(--orange-light-rgb);
    --tertiary-dark: var(--yellow-dark);
    --tertiary-dark-rgb: var(--yellow-dark-rgb);
    --tertiary: var(--yellow);
    --tertiary-rgb: var(--yellow-rgb);
    --tertiary-light: var(--yellow-light);
    --tertiary-light-rgb: var(--yellow-light-rgb);
  }

  * {
    box-sizing: border-box;
  }

  html {
    overflow-x: hidden;
    overflow-y: scroll;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    //scroll-behavior: smooth;
  }
  body, html {
    min-height: 100vh;
  }
  body {
    position: relative;
    background: var(--white);
  }

  html.is-scroll-disabled-nav body {
    height: 100vh;
    overflow: hidden;
    .nav--holder {
      &.nav-up {
        transform: translateY(0);
      }
    }
  }
  html.is-scroll-disabled body {
    height: 100vh;
    overflow: hidden;
    main {
      position: relative;
      z-index: 40;
    }
    .btn--chat {
      opacity: 0;
    }
  }

  .nav--holder {
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
    opacity: 0;
    &.nav-up {
      transform: translateY(-100%);
      transition: all 0.3s linear;
    }
    &.nav-down {
      transform: translateY(0);
      transition: all 0.3s linear;
    }
    &.is-active {
      .nav--links {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .wrapper {
      position: relative;
      min-height: 100vh;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  a {
      transition: all .15s linear;
      cursor: pointer;
      text-decoration: none;
  }

  hr {
    border: 0;
    border-bottom: .5px solid var(--black);
  }
  hr.hr--white {
    border-color: var(--white);
  }
  hr.hr--grey {
    border-color: #676561
  }

  .color--white {
    color: var(--white);
  }
  .color--main {
    color: var(--main);
  }
  .color--primary {
    color: var(--primary);
    path {
        fill: var(--primary);
    }
  }
  .color--primary-light {
    color: var(--primary-light);
  }
  .color--secondary {
    color: var(--secondary);
  }
  .color--secondary-light {
    color: var(--secondary-light);
  }
  .color--tertiary {
    color: var(--tertiary);
  }
  .color--tertiary-light {
    color: var(--tertiary-light);
  }
  .color--grey-1 {
    color: var(--grey-1);
  }
  .color--grey-2 {
    color: var(--grey-2);
  }
  .color--grey-3 {
    color: var(--grey-3);
  }
  .color--grey-4 {
    color: var(--grey-4);
  }
  .color--grey-5 {
    color: var(--grey-5);
  }
  .color--grey-6 {
    color: var(--grey-6);
  }

  .fill--main {
    fill: var(--main);
  }
  .fill--accent-1 {
    fill: var(--accent-1);
  }
  .fill--accent-2 {
    fill: var(--accent-2);
  }

  .stroke--main {
    stroke: var(--main);
    fill: none;
  }

  .bg--white {
    background-color: var(--white);
  }
  .bg--black {
    background-color: var(--black);
  }
  .bg--main {
    background-color: var(--main);
  }
  .bg--accent-1 {
    background-color: var(--accent-1);
  }
  .bg--accent-2 {
    background-color: var(--accent-2);
  }

  .bg--secondary {
    background-color: var(--secondary);
  }
  .bg--secondary-dark {
    background-color: var(--secondary-dark);
  }
  .bg--tertiary {
    background-color: var(--tertiary);
  }
  .bg--grey-1 {
    background-color: var(--grey-1);
  }
  .bg--grey-2 {
    background-color: var(--grey-2);
  }
  .bg--grey-5 {
    background-color: var(--grey-5);
  }

  .db {
    display: block;
  }

  .mb-5 {
    margin-bottom: 5px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }

  .w-14 {
    @media ${media.lgUp} {
      width: 140px;
    }
  }
  .w-17 {
    width: 170px;
  }
  .w-20 {
    width: 200px;
  }
  .w-25 {
    width: 250px;
  }
  .w-27 {
    width: 270px;
  }
  .w-450 {
    max-width: 450px;
  }

  .leading-125 {
    line-height: 125%;
  }

  .equipment {
    width: 150px;
    @media (min-width: 800px){
      width: 200px;
    }
    @media ${media.lgUp} {
      width: 270px;
    }
  }

  .p5 {
    padding-top: 6px;
  }
  .top-160 {
    top: calc(160px + 68px);
  }

  .border-top {
    border-top: 1px solid var(--grey-3);
  }

  .border--black {
    border: 0.5px solid var(--black);
  }

  .scroll--indicator {
    display: none;
    position: absolute;
    z-index: 11;
    left: -80px;
    bottom: 50px;
    font-size: 10px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--white);
    transform: rotate(-90deg);
    transition: all 0.3s linear;
    @media (min-width: 1180px){
      display: block;
      bottom: 171px;
    }
    &::after {
        position: absolute;
        top: 7px;
        margin: 0 0 0 25px;
        content: '';
        width: 138px;
        height: 1px;
        background: var(--white);
        opacity: 0.3;
    }
    &.hide {
        opacity: 0;
    }
  }

  .video--holder {
    height: 0;
    padding-bottom: 42%;
    overflow: hidden;
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &.video--holder--full {
        video {
            object-fit: cover;
        }
    }
  }

  // Modal
  .modal {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--grey-2);
    z-index: 40;
    height: 100vh;
    display: flex;
    align-items: center;
    transform: translateY(-100%);
    transition: all 0.4s cubic-bezier(0.4, 0, 1, 1); // Ease In
    &.is-active {
      transform: translateY(0);
      transition: all 0.5s cubic-bezier(0, 0, 0.2, 1); // Ease Out
    }
    &.modal--video {
      transform: translateY(0);
      opacity: 0;
      visibility: hidden;
      &.is-active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .model--open {
    overflow: hidden;
    height: 100vh;
    .modal--bg {
      opacity: 1;
      visibility: visible;
    }
  }
  .modal--bg {
    position: fixed;
    z-index: 18;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31,31,29, 0.08);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;
    cursor: pointer;
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

  //Accordion
  .accordion {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(0,1,0,1);
    .bb {
      border-bottom: 1px solid var(--grey-3);
    }
    &.is-active {
      max-height: 9999px;
      transition:max-height 0.4s cubic-bezier(1,0,1,0);
    }
  }
  .accordion--trigger {
    cursor: pointer;
    svg, polygon {
      transition: all 0.2s linear;
    }
    &.is-active {
      svg {
        .icon--plus--plus {
          opacity: 0;
        }
        transform: rotate(90deg);
      }
    }
  }

  // Image Swipe Transition
  @keyframes background-intro {
    0% {
      opacity: 0;
      -webkit-transform: scaleY(0) translateY(0);
      transform: scaleY(0) translateY(0);
    }
    10% {
      opacity: 1;
    }
    50% {
      -webkit-transform: scaleY(1) translateY(0);
      transform: scaleY(1) translateY(0);
    }
    to {
      -webkit-transform: scaleY(1) translateY(-105%);
      transform: scaleY(1) translateY(-105%);
    }
  }
  @keyframes image-intro {
    0% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
    20% {
      opacity: 1;
    }
    to {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .bg--swipe {
    padding-top: 116%;
    @media ${media.mdUp} {
      padding-top: 60%;
    }
    @media ${media.lgUp} {
      padding-top: 130%;
    }
    &.bg--swipe--portrait {
      padding-top: 116%;
      @media ${media.mdUp} {
        padding-top: 130%;
      }
      @media ${media.lgUp} {
        padding-top: 130%;
      }
    }
    &.bg--swipe--landscape {
      padding-top: 60%;
      @media ${media.mdUp} {
        padding-top: 60%;
      }
      @media ${media.lgUp} {
        padding-top: 60%;
      }
    }
    &.bg--swipe--bg::after {
      position: absolute;
      z-index: 1;
      content: '';
      top: -80px;
      right: 60px;
      width: 300%;
      height: 100%;
      background-color: var(--primary);
      @media ${media.lgUp} {
        top: -8rem;
        right: 0;
      }
    }
  }
  .bg--swipe--wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    visibility: hidden;
    &.is-active {
      visibility: visible;
      .bg--swipe--img {
        img {
          transform-origin: 50% 50%;
          animation: 0.6s cubic-bezier(0.28, 0, 0.49, 1) 0.5s both image-intro;
        }
        &::before {
          transform-origin: 50% 100%;
          animation: 1s cubic-bezier(0.49, 0.025, 0.49, 1) 0s forwards
            background-intro;
        }
      }
    }
  }
  .bg--swipe--img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      object-position: 50% 30%;
      object-fit: cover;
      animation: hidden 0s linear both;
    }
    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      background: var(--accent-1);
      animation: hidden 0s linear both;
    }
    &.secondary {
      &::before {
        background: var(--secondary-dark);
      }
    }
  }

  .osano-cm-dialog {
    background: #faf8ee;
    padding: 18px 60px;
    @media ${media.mdUp} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  .osano-cm-content {
    color: #96948a;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.01em;
    @media ${media.mdUp} {
      line-height: 140%;
    }
    @media ${media.lgUp} {
      line-height: 120%;
    }
  }
  .osano-cm-dialog--type_bar {
    .osano-cm-dialog__content {
      margin: 0;
      text-align: center;
      max-width: 655px;
      @media ${media.mdUp} {
        text-align: left;
      }
      @media ${media.xlUp} {
        max-width: 775px;
      }
    }
    .osano-cm-dialog__buttons {
      margin: 18px 0 0 0;;
      justify-content: center;
      min-width: 300px;
      @media ${media.mdUp} {
        max-width: 300px;
      }
      @media ${media.lgUp} {
        margin: 0 0 0 45px;
      }
    }
    .osano-cm-link {
      text-decoration: underline;
      color: #96948a;
    }
    .osano-cm-button {
      color: #78766f;
      display: inline-block;
      padding: 9px 26px;
      font-weight: bold;
      font-size: 10px;
      line-height: 100%;
      letter-spacing: 0.06em;
      margin: 0;
      text-transform: uppercase;
      background: #faf8ee;
      border: 1px solid #78766f;
      border-radius: 15px;
      width: auto;
      &:hover {
        background: var(--grey-2);
      }
    }
    .osano-cm-button--type_deny {
      background: #faf8ee;
      border: 1px solid #78766f;
      color: #78766f;
      margin-left: 20px;
      &:hover {
        background: var(--grey-2);
      }
    }
  }
  @media ${media.mdUp} {
    .osano-cm-dialog--type_bar .osano-cm-dialog__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
    video {
      &:focus {
        outline: 0;
      }
    }
    .volume--bar-1,
    .volume--bar-3 {
      height: 16px;
    }
    .modal--video.video--playing {
      opacity: 1;
      visibility: visible;
      .volume--bar-1 {
        animation: 1s linear 0s infinite bar1;
      }
      .volume--bar-2 {
        animation: 1s linear 0s infinite bar2;
      }
      .volume--bar-3 {
        animation: 1s linear 0s infinite bar3;
      }
    }
    @keyframes bar1 {
      0% {
        y: 9px
      }
      25% {
        y: 11px
      }
      75% {
        y: 3px
      }
    }
    @keyframes bar2 {
      0% {
        y: 0
      }
      25% {
        y: 5px
      }
      50% {
        y: 12px;
      }
      75% {
        y: 3px;
      }
    }
    @keyframes bar3 {
      0% {
        y: 6px
      }
      25% {
        y: 1px
      }
      50% {
        y: 7px;
      }
      75% {
        y: 2px;
      }
    }

    .why-futurefoods {
      .nav--holder.bg--white {
        background: var(--black);
        .logo-2 {
          display: none;
        }
        .logo-1 {
          display: block;
        }
        .btn--text.color--black {
          color: var(--white);
        }
        .btn.btn--border--black.color--black {
          border-color: rgba(var(--white-rgb),0.3);
          color: var(--white);
        }
        .btn.btn--border--none.color--black,
        .btn--text.btn--text--language.color--black {
          color: var(--white);
        }
        .icon--menu {
          fill: var(--white);
        }
      }
    }
    .nav--holder.bg--main.nav-scrolled {
      background: var(--grey-1);
      .logo-1 {
        display: none;
      }
      .logo-2 {
        display: block;
      }
      .btn--text.color--white {
        color: var(--grey-5);
      }
      .btn.btn--border--white.color--white {
        border-color: rgba(var(--grey-4-rgb),0.3);
        color: var(--grey-6);
      }
      .btn.btn--border--none.color--white,
      .btn--text.btn--text--language.color--white {
        color: var(--grey-6);
      }
      .icon--menu {
        fill: var(--grey-5);
      }
    }

    .dark-kitchen-restaurant #block-hero-form .bg--hero--holder,
    .dark-kitchen-restaurant #block-hero-form .bg--hero--holder--sm {
      @media ${media.lgUp} {
        margin-top: -255px;
      }
      @media ${media.xlUp} {
        margin-top: -301px;
      }
    }
    .virtual-kitchen #block-hero-form .bg--hero--holder,
    .virtual-kitchen #block-hero-form .bg--hero--holder--sm {
      @media ${media.lgUp} {
        margin-top: -346px;
      }
      @media ${media.xlUp} {
        margin-top: -420px;
      }
    }
    .ghost-kitchen #block-hero-form .bg--hero--holder,
    .ghost-kitchen #block-hero-form .bg--hero--holder--sm {
      @media ${media.lgUp} {
        margin-top: -258px;
      }
      @media ${media.xlUp} {
        margin-top: -331px;
      }
    }
    .central-kitchen #block-hero-form .bg--hero--holder,
    .central-kitchen #block-hero-form .bg--hero--holder--sm {
      @media ${media.lgUp} {
        margin-top: -412px;
      }
      @media ${media.xlUp} {
        margin-top: -399px;
      }
    }
    .commercial-kitchen #block-hero-form .bg--hero--holder,
    .commercial-kitchen #block-hero-form .bg--hero--holder--sm {
      @media ${media.lgUp} {
        margin-top: -312px;
      }
      @media ${media.xlUp} {
        margin-top: -399px;
      }
    }
    .shared-kitchen #block-hero-form .bg--hero--holder,
    .shared-kitchen #block-hero-form .bg--hero--holder--sm {
      @media ${media.lgUp} {
        margin-top: -375px;
      }
      @media ${media.xlUp} {
        margin-top: -375px;
      }
    }
    .kitchen-rental #block-hero-form .bg--hero--holder,
    .kitchen-rental #block-hero-form .bg--hero--holder--sm {
      @media ${media.lgUp} {
        margin-top: -300px;
      }
      @media ${media.xlUp} {
        margin-top: -375px;
      }
    }

`;

export default GlobalStyles;

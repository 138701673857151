import pagesJSON from './src/constants/pages.json';

const pageTypes = {
  'index.js': 'home',
  'why.js': 'why',
  'faq.js': 'faq',
  'cookies-policy.js': 'cookies-policy',
  'privacy-policy.js': 'privacy-policy',
  'blog.js': 'blog',
};

const pages = pagesJSON.map((page) => {
  const templateFilename = page.component.match(/[^/]+$/)?.[0] ?? 'index.js';
  return {
    ...page,
    type: pageTypes[templateFilename],
  };
});

export const linkResolver = (doc) => {
  if (doc.type === 'page') {
    const pageType = doc.data.page;
    if (pageType === 'blog') return '/blog';
    return pages.find((page) => page.type === pageType)?.path ?? '/';
  } else if (doc.type === 'landing_page') {
    return doc.data.locale === 'en-us'
      ? `/${doc.data.slug}/`
      : `/${doc.data.locale}/${doc.data.slug}/`;
  }
  return '/';
};

import { createGlobalStyle } from 'styled-components';

import { media } from './media';

const Grid = createGlobalStyle`
  .container {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    @media ${media.smUp} {
      padding-left: 32px;
      padding-right: 32px;
    }
    @media ${media.mdUp} {
      padding-left: 60px;
      padding-right: 60px;
    }
    @media ${media.xlUp} {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .grid-7-5 {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-template-rows: 1fr;
  }

  .align-end {
    align-items: flex-end;
  }
`;

export default Grid;

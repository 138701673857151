import { gsap } from 'gsap';
import React, { Component } from 'react';

import Buttons from '../styles/Buttons';
import Form from '../styles/Form';
import GlobalStyles from '../styles/GlobalStyles';
import Grid from '../styles/Grid';
import RtlStyles from '../styles/RtlStyles';
import Typography from '../styles/Typography';
import Footer from './Footer';
import GlobalMeta from './GlobalMeta';
import Nav from './Nav';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      contactActive: false,
    };
  }

  componentDidMount() {
    this.updateLayoutClass();

    const navElements = gsap.timeline();
    navElements
      .from('.nav--large li, .nav--large--eyebrow', {
        duration: 0.7,
        opacity: '0',
        delay: 0.4,
        y: '-7',
        stagger: {
          amount: '0.5',
        },
      })
      .from(
        '.fade-1',
        {
          opacity: '0',
          y: '-7',
          duration: 0.3,
          delay: 0.4,
        },
        0
      );

    this.animationNav = navElements;
  }

  componentDidUpdate() {
    this.updateLayoutClass();
  }

  toggleNavModal = () => {
    const html = document.querySelector('html');
    const wrapper = document.querySelector('.wrapper');
    const modalBg = document.querySelector('.modal--bg');
    const htmlActive = document.querySelector('html.is-scroll-disabled-nav');
    if (htmlActive) {
      const scrollY = wrapper.style.top;
      this.setState({ active: false, contactActive: false });
      modalBg.classList.remove('is-active');
      html.classList.remove('is-scroll-disabled-nav');
      wrapper.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    } else {
      this.setState({ active: true, contactActive: false });
      this.animationNav.restart().play();
      modalBg.classList.add('is-active');
      setTimeout(function () {
        wrapper.style.top = `-${document.documentElement.scrollTop}px`;
        html.classList.add('is-scroll-disabled-nav');
      }, 500);
    }
  };

  handBackToMenu = () => {
    this.toggleContactModal();
    this.toggleNavModal();
  };

  handleContact = () => {
    if (this.state.active) {
      this.toggleNavModal();
    }
    this.toggleContactModal();
  };

  toggleContactModal = () => {
    const html = document.querySelector('html');
    const wrapper = document.querySelector('.wrapper');
    const modalBg = document.querySelector('.modal--bg');
    const htmlActive = document.querySelector('html.is-scroll-disabled-nav');
    if (htmlActive) {
      const scrollY = wrapper.style.top;
      this.setState({ active: false, contactActive: false });
      modalBg.classList.remove('is-active');
      html.classList.remove('is-scroll-disabled-nav');
      wrapper.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    } else {
      this.setState({ active: false, contactActive: true });
      this.animationNav.restart().play();
      modalBg.classList.add('is-active');
      setTimeout(function () {
        wrapper.style.top = `-${document.documentElement.scrollTop}px`;
        html.classList.add('is-scroll-disabled-nav');
      }, 500);
    }
  };

  getWrapperClass = (langKey) => {
    switch (langKey) {
      case 'ar-ae':
      case 'ar-kw':
      case 'ar-sa':
        return 'wrapper wrapper--rtl';
      default:
        return 'wrapper';
    }
  };

  updateLayoutClass() {
    const url =
      typeof window !== 'undefined' ? window.location.pathname.split('/') : '';
    const layoutHolder = document.getElementById('layout--holder');
    if (url && url.length > 2 && url[2]) {
      layoutHolder.className = url[2];
    } else if (url && url[1]) {
      layoutHolder.className = url[1];
    } else {
      layoutHolder.className = '';
    }
  }

  render() {
    const { children } = this.props;
    const isPreview = this.props.pageContext.url.indexOf('_preview') > -1;
    // const url = typeof window !== 'undefined' ? window.location.pathname.split('/') : '';
    const langKey =
      typeof this.props.pageContext.langKey !== 'undefined'
        ? this.props.pageContext.langKey
        : 'en-us';

    if (isPreview) return <div id="layout--holder">{children}</div>;

    return (
      <div id="layout--holder">
        <GlobalStyles data={this.props.data.global} />
        <GlobalMeta
          data={this.props.data.global}
          langKey={langKey}
          path={this.props.path}
        />
        <Typography />
        <Grid />
        <Buttons />
        <Form />
        <RtlStyles />
        <div className={this.getWrapperClass(langKey)}>
          <Nav
            data={this.props.data.global}
            langKey={langKey}
            location={this.props.location}
            navModalActive={this.state.active}
            contactModalActive={this.state.contactActive}
            handleContact={this.handleContact}
            handBackToMenu={this.handBackToMenu}
            toggleContactModal={this.toggleContactModal}
            toggleNavModal={this.toggleNavModal}
            path={this.props.path}
            siteTag={this.props.pageContext.siteTag}
            pageContext={this.props.pageContext}
          />
          {children}
          <Footer
            data={this.props.data.global}
            langKey={langKey}
            path={this.props.path}
            siteTag={this.props.pageContext.siteTag}
          />
        </div>
      </div>
    );
  }
}

export default Layout;

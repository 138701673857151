import { lazy } from 'react';

const LandingPage = lazy(() => import('./src/templates/landing-page'));
const HomePage = lazy(() => import('./src/templates/index'));
const WhyPage = lazy(() => import('./src/templates/why'));
const BlogPage = lazy(() => import('./src/templates/blog'));
const FAQPage = lazy(() => import('./src/templates/faq'));
const CookiesPolicyPage = lazy(() => import('./src/templates/cookies-policy'));
const PrivacyPolicyPage = lazy(() => import('./src/templates/privacy-policy'));

export function componentResolver(nodes) {
  return nodes
    .map((node) => {
      const acceptedPageTypes = ['page', 'landing_page'];

      if (!acceptedPageTypes.includes(node.type)) return null;

      if (node.type === 'landing_page') return LandingPage;

      switch (node.data.page) {
        case 'blog':
          return BlogPage;
        case 'cookies-policy':
          return CookiesPolicyPage;
        case 'faq':
          return FAQPage;
        case 'privacy-policy':
          return PrivacyPolicyPage;
        case 'why':
          return WhyPage;
        case 'home':
          return HomePage;
      }
    })
    .filter(Boolean);
}

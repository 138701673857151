import { navigate } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { gsap } from 'gsap';
import React, { Component } from 'react';
import styled from 'styled-components';

import translate from '../constants/translations.json';
import { media } from '../styles/media';
import CountrySelector from './CountrySelector';

const Section = styled.section`
  transition: all 0.3s ease-in;
  .logo-1 {
    display: none;
  }
  .logo--wrapper {
    left: 18px;
    top: 20px;
    @media ${media.smUp} {
      left: 33px;
    }
  }
  @media (max-width: 719px) {
    #btn--chat-with-us {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .logo-1,
  .logo-2 {
    height: 13px;
    position: relative;
    top: 2px;
    width: auto;
    @media ${media.mdUp} {
      height: 15px;
      top: auto;
    }
    @media ${media.lgUp} {
      height: 18px;
    }
  }
  .btn--back-to-menu {
    display: none;
  }
  &.is-active-contact {
    .logo--holder {
      display: none;
    }
    .btn--back-to-menu {
      display: block;
    }
    @media ${media.mdUp} {
      .logo--holder {
        display: block;
      }
      .btn--back-to-menu {
        display: none;
      }
    }
  }
  &.is-active {
    &.bg--main {
      background: var(--white) !important;
    }
    /*
    .logo-1 {
      display: none;
    }
    .logo-2 {
      display: block;
    }
    */
    .btn {
      border-color: rgba(var(--grey-4-rgb), 0.3);
      color: var(--grey-6);
    }
    .btn--border--none {
      border-color: transparent;
    }
  }
  .btn--text--language {
    top: 3px;
  }
`;

const NavStyles = styled.nav`
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 0;
  align-items: center;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
  }
  .nav--links {
    transition: all 0.3s ease-in;
    li {
      display: inherit;
    }
  }
  .nav--hide {
    transition: all 0.3s ease-in;
  }
  .nav--cta {
    justify-content: flex-end;
  }
  .nav--icon--menu {
    padding-left: 34px;
  }
  .icon--menu {
    width: 12px;
    display: block;
    fill: var(--black);
    transition: all 0.15s linear;
    .line-1 {
      transform-origin: right center;
      transition: all 0.15s linear;
    }
    .line-2 {
      transform-origin: right bottom;
      transition: all 0.15s linear;
    }
    .line-3 {
      transform-origin: right top;
      transition: all 0.15s linear;
    }
    &:hover {
      .line-1 {
        width: 14px;
      }
    }
  }
  .is-active {
    .icon--menu {
      fill: var(--black);
      .line-1 {
        width: 0;
      }
      .line-2 {
        transform: rotate(45deg) translate(-2px, 2px);
        width: 12px;
      }
      .line-3 {
        transform: rotate(-45deg) translate(-2px, -1px);
        width: 12px;
      }
    }
  }
  .nav--dropdown--trigger {
    & > a > svg {
      display: inline-block;
      position: relative;
      top: auto;
      right: auto;
      margin-left: 4px;
      transition: all 0.3s ease;
      transform: rotate(180deg);
    }
  }
  .nav--dropdown--trigger:hover {
    .nav--dropdown {
      display: block;
      height: 150px;
      opacity: 1;
    }
    .nav--dropdown--language {
      height: auto;
    }
    .nav--dropdown--language {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-right: 0;
      width: calc(100vw - 110px);
      @media ${media.mdUp} {
        width: 420px;
      }
      a {
        padding-right: 20px;
        svg {
          display: none;
          position: static;
          // right: 20px;
          @media ${media.mdUp} {
            display: inline-block;
          }
        }
      }
    }
    & > a > svg {
      transform: rotate(0deg);
    }
  }
  .nav--dropdown {
    display: block;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 0;
    top: 17px;
    width: 210px;
    opacity: 0;
    a {
      font-size: 14px;
      font-weight: normal;
      line-height: 170%;
      letter-spacing: 0.02em;
      text-transform: none;
      &:hover {
        color: #000;
        svg {
          transform: translate(3px, -3px);
        }
      }
      svg {
        transition: all 0.2s ease;
        top: 8px;
        right: 0;
      }
    }
  }
  .nav--dropdown--language {
    left: auto;
    right: 0;
    top: 20px;
    width: 210px;
  }
`;

const NavDropdown = styled.div`
  position: fixed;
  overflow-y: scroll;
  top: 0;
  z-index: 19;
  width: 100%;
  height: 100vh;
  transform: translate(0, -150%);
  transition: all 0.4s cubic-bezier(0.4, 0, 1, 1); // Ease In
  @media (min-height: 900px) {
    height: auto;
  }
  @media screen and ${media.lgUp} and (min-height: 750px) {
    /* height: auto; */
  }
  .container {
    height: 90%;
    @media ${media.mdUp} {
      height: 100%;
      /* height: auto; */
    }
    @media ${media.lgUp} {
      height: auto;
    }
    @media screen and ${media.mdUp} and (max-height: 759px) {
      height: auto;
    }
  }
  &.is-active {
    transform: translate(0, 0);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1); // Ease Out
  }
  .nav--contact {
    a {
      font-size: 14px;
      color: var(--grey-6);
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
  @media ${media.lgUp} {
    .nav--contact--lg {
      width: 125%;
    }
  }
  .nav--large {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 10px;
      @media ${media.mdUp} {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      display: inline-block;
      padding: 0 0 10px 0;
      position: relative;
      font-size: 32px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: var(--grey-6);
      overflow: hidden;
      @media ${media.mdUp} {
        font-size: 36px;
      }
      @media ${media.lgUp} {
        font-size: 46px;
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 2px;
        background: var(--grey-6);
        transition: all 0.15s linear;
        transform: translateX(calc(-100% - 0.1rem));
        animation: 0.25s linear 0s line-out forwards;
      }
      &:hover,
      &:focus {
        &::before {
          animation: 0.25s linear 0s line-in forwards;
        }
      }
    }
  }
  .form-1,
  .form-2 {
    min-height: 0;
  }
  /* .hide-height {
    display: none;
    @media screen and ${media.mdUp} and (min-height: 760px) {
      display: block;
    }
  } */
  /* .height-margin {
    @media screen and ${media.mdUp} and (max-height: 759px) {
      margin-top: 0;
      padding-top: 0;
    }
  } */
`;

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      contactActive: false,
      firstName: '',
      lastName: '',
      phone: '',
      company: '',
      email: '',
      orders: '',
      country: '',
      locations: '',
    };
  }

  stateHandler = (state) => {
    this.setState(state);
  };

  componentDidMount() {
    let didNavScroll = false;
    let lastScrollTop = 0;
    const delta = 5;
    const navHolder = document.querySelector('.nav--holder');
    const navHolderHeight = navHolder.clientHeight;

    function checkNavScroll() {
      didNavScroll = true;
    }

    function hasNavScrolled() {
      const st = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.scrollHeight;

      if (Math.abs(lastScrollTop - st) <= delta) return;

      if (st > lastScrollTop && st > navHolderHeight) {
        // Scroll Down
        navHolder.classList.remove('nav-down');
        navHolder.classList.add('nav-up');
      } else {
        // Scroll Up
        if (st + windowHeight < documentHeight) {
          navHolder.classList.add('nav-down');
          navHolder.classList.remove('nav-up');
        }
        if (st === 0) {
          navHolder.classList.remove('nav-down');
        }
      }

      if (st > 0) {
        navHolder.classList.add('nav-scrolled');
      } else {
        navHolder.classList.remove('nav-scrolled');
      }

      lastScrollTop = st;
    }

    setInterval(function () {
      if (didNavScroll) {
        hasNavScrolled();
        didNavScroll = false;
      }
    }, 250);

    window.addEventListener('scroll', checkNavScroll);

    const fadeElements = gsap.timeline();
    fadeElements.to('.nav--holder', {
      opacity: 1,
      delay: 0.5,
    });
  }

  componentDidUpdate() {
    if (typeof location !== 'undefined' && location.hash === '#contact') {
      setTimeout(function () {
        const btn = document.getElementById('btn--contact-us');
        btn.click();
      }, 500);
    }
  }

  handleContact = (event) => {
    event.preventDefault();
    if (this.props.navModalActive) {
      this.props.toggleNavModal();
    }
    const el = document.getElementById('contact');
    if (el === null) {
      navigate('/#contact');
    } else {
      el.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  render() {
    const langKey =
      this.props.langKey !== undefined ? this.props.langKey : 'en-us';
    const global =
      this.props.data !== undefined ? this.props.data.edges[0].node.data : '';
    const isCondensedNav =
      this.props.pageContext?.pageType === 'landing' ? true : false;
    return (
      <>
        <Section
          className={`nav--holder bg--white${
            this.props.navModalActive ? ' is-active' : ''
          }${
            this.props.contactModalActive ? ' is-active is-active-contact' : ''
          }`}>
          <NavStyles className="container relative pt-160 md:grid md:grid-cols-8 lg:grid-cols-12">
            <div className="logo--wrapper absolute md:static md:left-auto md:top-auto md:col-span-2 lg:col-span-2">
              <AniLink
                to={langKey === 'en-us' ? `/` : `/${langKey}/`}
                cover
                direction="up"
                duration={1}
                bg="#FAFAFA"
                className="logo--holder">
                <img
                  src={global.logo_light.url}
                  alt={this.props.siteName}
                  className="db logo-1"
                />
                <img
                  src={global.logo_dark.url}
                  alt={this.props.siteName}
                  className="logo-2"
                />
              </AniLink>
              <a
                className="btn--text btn--text--left btn--back-to-menu"
                onClick={this.props.handBackToMenu}>
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.98175 12.7716L0.773997 6.54057L6.98175 0.30957L8.1675 1.49532L3.91275 5.65707L12.771 5.65707L12.771 7.42407L3.91275 7.42407L8.1675 11.5858L6.98175 12.7716Z"
                    fill="#3D3D3D"
                  />
                </svg>
                Back to Menu
              </a>
            </div>

            <ul
              className={`nav--links col-span-6 lg:col-span-10 ${
                isCondensedNav
                  ? 'flex justify-end'
                  : 'hidden lg:flex lg:justify-end'
              }`}>
              {!isCondensedNav && (
                <>
                  {global?.top_nav?.map((item, index) => (
                    <li className="mr-45" key={index}>
                      <AniLink
                        cover
                        to={item.url}
                        direction="up"
                        duration={1}
                        bg="#FAFAFA"
                        className="btn--text color--black">
                        <span
                          style={{ whiteSpace: 'nowrap' }}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      </AniLink>
                    </li>
                  ))}

                  <li className="mr-45">
                    <CountrySelector langKey={langKey} />
                  </li>
                </>
              )}

              <li
                className={`nav--hide  ${
                  isCondensedNav ? 'flex' : 'hidden lg:flex'
                }`}>
                {!this.props.contactModalActive && (
                  <a
                    id="btn--contact-us"
                    ref="contactRef"
                    onClick={this.handleContact}
                    className="btn btn--border--black color--black db">
                    {global?.contact_book_a_demo}
                  </a>
                )}
              </li>
            </ul>

            {!isCondensedNav && (
              <ul className="nav--cta col-span-2 flex md:col-span-6 lg:hidden">
                <li>
                  <CountrySelector langKey={langKey} />
                </li>
                <li className={`nav--icon--menu`}>
                  <a
                    onClick={this.props.toggleNavModal}
                    className={`${
                      this.props.navModalActive || this.props.contactModalActive
                        ? 'is-active'
                        : ''
                    }`}>
                    <svg
                      version="1.1"
                      viewBox="0 0 12 11"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      className="icon--menu">
                      <rect
                        x="0"
                        y="4.1"
                        className="line-1"
                        width="8"
                        height="1.6"
                      />
                      <rect
                        x="0"
                        y="8.4"
                        className="line-2"
                        width="12"
                        height="1.6"
                      />
                      <rect
                        x="0"
                        y="0"
                        className="line-3"
                        width="12"
                        height="1.6"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            )}
          </NavStyles>
          <hr />
        </Section>

        <Section>
          <NavDropdown
            className={`bg--white pt-100 md:pb-50 md:pt-150 lg:pt-190 ${
              this.props.navModalActive ? 'is-active' : ''
            }`}>
            <div className="lg:align-end container grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 lg:items-end">
              <div className="fade-1 col-span-8 hidden md:mt-80 lg:col-span-4 lg:mt-0 lg:block">
                <p className="h4 color--grey-6 mb-6">
                  {global.contact_heading.raw[0].text}
                </p>
                <p className="small color--grey-5">
                  {global.contact_content.raw[0].text}
                </p>
                <div className="nav--contact nav--contact--lg mt-12 flex lg:mb-50">
                  <div className="mr-45 inline-block">
                    <p className="h6 color--grey-6 md mb-0">
                      {translate[`${langKey}`].email}
                    </p>
                    <p className="small mb-0">
                      <a
                        href={`mailto:${global.contact_email}`}
                        className="menu--link color--grey-5">
                        {global.contact_email}
                      </a>
                    </p>
                  </div>
                  <div className="inline-block">
                    <p className="h6 color--grey-6 md mb-0">
                      {translate[`${langKey}`].phone}
                    </p>
                    <p className="small">
                      <a
                        href={`tel:${global.contact_phone}`}
                        className="menu--link color--grey-5">
                        {global.contact_phone}
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <nav className="col-span-5 lg:col-start-8">
                <ul className="nav--large mb-50">
                  {global.top_nav?.map((item, index) => (
                    <li key={index}>
                      <AniLink
                        cover
                        direction="up"
                        duration={1}
                        bg="#FAFAFA"
                        to={item.url}
                        onClick={this.props.toggleNavModal}>
                        {item.title}
                      </AniLink>
                    </li>
                  ))}
                  <li>
                    <a onClick={this.handleContact}>
                      {global?.contact_book_a_demo}
                    </a>
                  </li>
                </ul>
              </nav>

              <div className="col-span-4 md:col-span-8 lg:col-span-12">
                <div className="mt-50 pb-20 md:mt-0 md:pb-0">
                  <hr className="hr--grey-4 mb-8" />
                  <p className="xsmall float-right mb-0 ml-20">
                    <AniLink
                      cover
                      direction="up"
                      duration={1}
                      bg="#FAFAFA"
                      to={
                        langKey === 'en-us'
                          ? `/cookies-policy/`
                          : `/${langKey}/cookies-policy/`
                      }
                      onClick={this.props.toggleNavModal}>
                      {translate[`${langKey}`].cookie_policy}
                    </AniLink>
                  </p>
                  <p className="xsmall float-right mb-0">
                    <AniLink
                      cover
                      direction="up"
                      duration={1}
                      bg="#FAFAFA"
                      to={
                        langKey === 'en-us'
                          ? `/privacy-policy/`
                          : `/${langKey}/privacy-policy/`
                      }
                      onClick={this.props.toggleNavModal}>
                      {translate[`${langKey}`].privacy_policy}
                    </AniLink>
                  </p>
                  <p className="xsmall col-span-3 mb-0">
                    &copy;{new Date().getFullYear()}.{' '}
                    {translate[`${langKey}`].all_rights_reserved}.
                  </p>
                  <div className="clear-both" />
                </div>
              </div>
            </div>
          </NavDropdown>
        </Section>
        <div className="modal--bg" onClick={this.props.toggleNavModal}></div>
      </>
    );
  }
}
export default Nav;

import React from 'react';
import { Helmet } from 'react-helmet';

const osano = process.env.GATSBY_OSANO;

if (!osano) {
  throw new Error('GATSBY_OSANO env var is missing but required');
}

export default function GlobalMeta({ data, path }) {
  const global = data.edges[0].node.data;
  return (
    <Helmet>
      {global.favicon.url && (
        <link
          href={global.favicon.url}
          rel="shortcut icon"
          type="image/x-icon"
        />
      )}
      {global.social_share_image.url && path.indexOf('/blog/') < 0 && (
        <meta property="og:image" content={global.social_share_image.url} />
      )}
      {global.social_share_image.url && path.indexOf('/blog/') < 0 && (
        <meta
          property="og:image:secure_url"
          content={global.social_share_image.url}
        />
      )}
      {global.social_share_image.url && path.indexOf('/blog/') < 0 && (
        <meta property="og:image:width" content="1200" />
      )}
      {global.social_share_image.url && path.indexOf('/blog/') < 0 && (
        <meta property="og:image:height" content="630" />
      )}
      {global.social_share_image.url && (
        <meta name="twitter:image" content={global.social_share_image.url} />
      )}
      {osano && <script src={osano} />}
    </Helmet>
  );
}

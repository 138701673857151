import { createGlobalStyle } from 'styled-components';

import InterDisplayBlack from '../assets/fonts/InterDisplay-Black.woff';
import InterDisplayBlack2 from '../assets/fonts/InterDisplay-Black.woff2';
import InterDisplayBlackItalic from '../assets/fonts/InterDisplay-BlackItalic.woff';
import InterDisplayBlackItalic2 from '../assets/fonts/InterDisplay-BlackItalic.woff2';
import InterDisplayBold from '../assets/fonts/InterDisplay-Bold.woff';
import InterDisplayBold2 from '../assets/fonts/InterDisplay-Bold.woff2';
import InterDisplayBoldItalic from '../assets/fonts/InterDisplay-BoldItalic.woff';
import InterDisplayBoldItalic2 from '../assets/fonts/InterDisplay-BoldItalic.woff2';
import InterDisplayExtraBold from '../assets/fonts/InterDisplay-ExtraBold.woff';
import InterDisplayExtraBold2 from '../assets/fonts/InterDisplay-ExtraBold.woff2';
import InterDisplayExtraBoldItalic from '../assets/fonts/InterDisplay-ExtraBoldItalic.woff';
import InterDisplayExtraBoldItalic2 from '../assets/fonts/InterDisplay-ExtraBoldItalic.woff2';
import InterDisplayExtraLight from '../assets/fonts/InterDisplay-ExtraLight.woff';
import InterDisplayExtraLight2 from '../assets/fonts/InterDisplay-ExtraLight.woff2';
import InterDisplayExtraLightItalic from '../assets/fonts/InterDisplay-ExtraLightItalic.woff';
import InterDisplayExtraLightItalic2 from '../assets/fonts/InterDisplay-ExtraLightItalic.woff2';
import InterDisplayItalic from '../assets/fonts/InterDisplay-Italic.woff';
import InterDisplayItalic2 from '../assets/fonts/InterDisplay-Italic.woff2';
import InterDisplayLight from '../assets/fonts/InterDisplay-Light.woff';
import InterDisplayLight2 from '../assets/fonts/InterDisplay-Light.woff2';
import InterDisplayLightItalic from '../assets/fonts/InterDisplay-LightItalic.woff';
import InterDisplayLightItalic2 from '../assets/fonts/InterDisplay-LightItalic.woff2';
import InterDisplayMedium from '../assets/fonts/InterDisplay-Medium.woff';
import InterDisplayMedium2 from '../assets/fonts/InterDisplay-Medium.woff2';
import InterDisplayMediumItalic from '../assets/fonts/InterDisplay-MediumItalic.woff';
import InterDisplayMediumItalic2 from '../assets/fonts/InterDisplay-MediumItalic.woff2';
import InterDisplayRegular from '../assets/fonts/InterDisplay-Regular.woff';
import InterDisplayRegular2 from '../assets/fonts/InterDisplay-Regular.woff2';
import InterDisplaySemiBold from '../assets/fonts/InterDisplay-SemiBold.woff';
import InterDisplaySemiBold2 from '../assets/fonts/InterDisplay-SemiBold.woff2';
import InterDisplaySemiBoldItalic from '../assets/fonts/InterDisplay-SemiBoldItalic.woff';
import InterDisplaySemiBoldItalic2 from '../assets/fonts/InterDisplay-SemiBoldItalic.woff2';
import InterDisplayThin from '../assets/fonts/InterDisplay-Thin.woff';
import InterDisplayThin2 from '../assets/fonts/InterDisplay-Thin.woff2';
import InterDisplayThinItalic from '../assets/fonts/InterDisplay-ThinItalic.woff';
import InterDisplayThinItalic2 from '../assets/fonts/InterDisplay-ThinItalic.woff2';
import { media } from './media';

const Typography = createGlobalStyle`
@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url(${InterDisplayThin2}) format("woff2"),
    url(${InterDisplayThin}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url(${InterDisplayThinItalic2}) format("woff2"),
    url(${InterDisplayThinItalic}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url(${InterDisplayExtraLight2}) format("woff2"),
    url(${InterDisplayExtraLight}) format("woff");
}
@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url(${InterDisplayExtraLightItalic2}) format("woff2"),
    url(${InterDisplayExtraLightItalic}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url(${InterDisplayLight2}) format("woff2"),
    url(${InterDisplayLight}) format("woff");
}
@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url(${InterDisplayLightItalic2}) format("woff2"),
    url(${InterDisplayLightItalic}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url(${InterDisplayRegular2}) format("woff2"),
    url(${InterDisplayRegular}) format("woff");
}
@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url(${InterDisplayItalic2}) format("woff2"),
    url(${InterDisplayItalic}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url(${InterDisplayMedium2}) format("woff2"),
    url(${InterDisplayMedium}) format("woff");
}
@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url(${InterDisplayMediumItalic2}) format("woff2"),
    url(${InterDisplayMediumItalic}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url(${InterDisplaySemiBold2}) format("woff2"),
    url(${InterDisplaySemiBold}) format("woff");
}
@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url(${InterDisplaySemiBoldItalic2}) format("woff2"),
    url(${InterDisplaySemiBoldItalic}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url(${InterDisplayBold2}) format("woff2"),
    url(${InterDisplayBold}) format("woff");
}
@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url(${InterDisplayBoldItalic2}) format("woff2"),
    url(${InterDisplayBoldItalic}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url(${InterDisplayExtraBold2}) format("woff2"),
    url(${InterDisplayExtraBold}) format("woff");
}
@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url(${InterDisplayExtraBoldItalic2}) format("woff2"),
    url(${InterDisplayExtraBoldItalic}) format("woff");
}

@font-face {
  font-family: 'InterDisplay';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url(${InterDisplayBlack2}) format("woff2"),
    url(${InterDisplayBlack}) format("woff");
}
@font-face {
  font-family: 'InterDisplay';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url(${InterDisplayBlackItalic2}) format("woff2"),
    url(${InterDisplayBlackItalic}) format("woff");
}

    html {
      font-family: 'InterDisplay', 'Open Sans', 'Helvetica Neue', sans-serif;
      color: var(--black);
    }

    .h1 {
      font-size: 56px;
      font-weight: 400;
      line-height: 90%;
      letter-spacing: -.01em;
      @media ${media.mdUp} {
        font-size: 128px;
      }
      @media ${media.lgUp} {
        font-size: 186px;
      }
    }
    .eyebrow {
      font-weight: 400;
      font-size: 10px;
      line-height: 160%;
      letter-spacing: 0.01em;
      @media ${media.lgUp} {
        font-size: 12px;
      }
    }
    .h2 {
      font-weight: 400;
      font-size: 48px;
      line-height: 92%;
      letter-spacing: -0.01em;
      @media ${media.mdUp} {
        font-size: 75px;
      }
      @media ${media.lgUp} {
        font-size: 105px;
      }
    }
    .h3,
    .blog-article-body h1 {
      font-weight: 360;
      font-size: 36px;
      line-height: 92%;
      letter-spacing: 0em;
      @media ${media.mdUp} {
        font-size: 45px;
      }
      @media ${media.lgUp} {
        font-size: 65px;
      }
    }
    .h4,
    .blog-article-body h2 {
      font-weight: 400;
      font-size: 32px;
      line-height: 92%;
      letter-spacing: 0em;
      @media ${media.lgUp} {
        font-size: 45px;
      }
    }
    .h5,
    .richtext--holder h5 {
      font-weight: 400;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0em;
      @media ${media.lgUp} {
        font-size: 36px;
      }
    }
    .h6,
    .richtext--holder h6,
    .blog-article-body h3,
    .blog-article-body h4,
    .blog-article-body h5,
    .blog-article-body h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 110%;
      letter-spacing: 0em;
      @media ${media.lgUp} {
        font-size: 24px;
      }
    }
    .richtext--holder a {
      text-decoration: underline;
    }
    .large,
    .large p,
    p.large {
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 24px;
      line-height: 125%;
      letter-spacing: 0.02em;
      @media ${media.lgUp} {
        font-size: 32px;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.01em;
      @media ${media.mdUp} {
        font-size: 18px;
      }
      @media ${media.lgUp} {
        font-size: 20px;
      }
    }
    p.small,
    .richtext--holder.small p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.01em;
      @media ${media.mdUp} {
        font-size: 18px;
      }
      @media ${media.lgUp} {
        font-size: 20px;
      }
    }
    .xsmall,
    p.xsmall,
    .xsmall p {
      font-weight: 400;
      font-size: 10px;
      line-height: 160%;
      letter-spacing: 0.03em;
      @media ${media.lgUp} {
        font-size: 12px;
        line-height: 150%;
      }
    }
    .xxsmall,
    p.xxsmall,
    .xxsmall p {
      font-size: 8px;
      @media ${media.smUp} {
        font-size: 10px;
      }
    }
    caption, .caption {
      font-weight: 600;
      font-size: 10px;
      line-height: 120%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      @media ${media.lgUp} {
        font-size: 12px;
      }
      a:hover,
      a:focus {
        opacity: 0.9;
      }
    }
    sup {
      font-size: 65%;
    }
    .md {
      font-weight: 500;
    }
    .o-hover {
      &:hover, &:focus {
        opacity: 0.9;
      }
    }
    cta,
    .cta {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
`;

export default Typography;

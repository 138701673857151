import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';

import { repositoryConfigs } from './prismic-previews';
import Layout from './src/components/Layout';
import './src/css/index.css';

gsap.registerPlugin(ScrollTrigger);

// Adds a shared React Context for Prismic preview sessions.
export const wrapPageElement = ({ element, props }) => {
  return (
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <Layout {...props}>{element}</Layout>
    </PrismicPreviewProvider>
  );
};

// Function to control the scrolling while routing
export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  const currentPosition = getSavedScrollPosition(routerProps.location);
  window.scrollTo(...(currentPosition || [0, 0]));

  return false;
};
